import React from 'react'
import './Button.scss'

export default function Button({ text, cls, onClick }) {
  const css = ['Button', ...cls]
  return (
    <div className={css.join(' ')} onClick={onClick}>
      { text }
    </div>
  )
}
