import React, { useState } from 'react'
import './Select.scss'

export default function Select({ options, cls }) {

  const [selected, setSelectedOption] = useState(0)

  const css = ['Select', ...cls]

  const renderOptions = (options) => {
    return options.map((option, index) => (
      <option 
        className={'option-select'} 
        value={option.value} 
        disabled={option.disabled} 
        key={index + Math.random().toFixed(6)}
      >
        {option.text}
      </option>
    ))
  }

  const handleChange = (e) => {
    setSelectedOption(e.target.value)
  }

  return (
    <label>
      <select value={selected} className={css.join(' ')} onChange={handleChange} >
        {
          renderOptions(options)
        }
      </select>
    </label>
  )
}
