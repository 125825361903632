import React from 'react'
import Questionarie from '../../components/Questionarie/Questionarie'
import './Main.scss'

export default function Main({ handleInstallClick }) {
  const propositionsData = [
    { src: './images/left.png', title: '2 million singles', text: 'From all around the world' },
    { src: './images/middle.png', title: 'On your terms', text: 'Find someone that’s right for you' },
    { src: './images/right.png', title: '20 million matches', text: 'Matches are made every minute' },
  ]

  const renderPropositions = (data) => {
    return data.map((el, index) => {
      return (
        <div className="propositions_item direction-column" key={index + Math.random().toFixed(5)}>
          <img className="item_img" src={el.src} alt={`propos${index}`}/>
          <div className="item_text direction-column">
            <span className="item_title">{el.title}</span>
            <span className="item_info">{el.text}</span>
          </div>
        </div>
      )
    })
  }

  return (
    <main className="Main direction-column">
      <div className="main-bg" style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + window.innerWidth < 480 ? 'images/main_bg_small.jpg' : 'images/main_bg.jpg'})`
      }}>
        <div className="main-questionarie">
          <Questionarie handleInstallClick={handleInstallClick} />
        </div>
      </div>
      <div className="propositions">
        <div className="container">
          <div className="propositions-content">
            {
              renderPropositions(propositionsData)
            }
          </div>
        </div>
      </div>
    </main>
  )
}
