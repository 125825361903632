import React from 'react'
import { Link } from 'react-router-dom'
import './Footer.scss'

export default function Footer() {
  return (
    <footer className="Footer">
      <div className="container">
        <div className="content">
          <div className="navigation">
            <Link to={'/about'}>
              About
            </Link>
            <a href="https://dilmil.helpshift.com/a/dil-mil" target="_blank" rel="noreferrer">FAQ</a>
            <a href="https://angel.co/company/gravitygroup/jobs" target="_blank" rel="noreferrer">Careers</a>
          </div>
          <div className="privacy">
            <span>Dil Mil Inc. Read our <a href="https://cdn.dilmil.co/static/privacy-policy/2021-03-17.pdf" target="_blank" rel="noreferrer">Privacy Policy</a> and <a href="https://cdn.dilmil.co/static/terms-of-use/2021-03-18.pdf" target="_blank" rel="noreferrer">Terms of Service</a></span>
          </div>
        </div>
      </div>
    </footer>
  )
}
