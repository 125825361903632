import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'
import  gtag, { install } from 'ga-gtag';
import OneLinkUrlGenerator from './AppsFlyerLinkGenerator'
import Header from './components/Header/Header'
import Main from './pages/Main/Main'
import About from './pages/About/About'
import Footer from './components/Footer/Footer'

class App extends Component {

  state = {
    urlAppLink: ''
  }

  handleInstallClick = () => {
    gtag('event', 'click_to_install')
    window.ttq.track('ClickInDownloadPage')
}

  componentDidMount() {
    (function(){
        window.AF = Object.assign((window.AF || {}),{OneLinkUrlGenerator: OneLinkUrlGenerator});
    })();

    const linkGenerator =  new window.AF.OneLinkUrlGenerator({
        oneLinkURL: "https://olivevideochat.onelink.me/F0qB",
        pidKeysList: ['af_pid', 'pid', 'utm_source'],
        campaignKeysList: ['af_c', 'c', "campaign.name", 'utm_campaign'],
        gclIdParam: "af_sub2",
        campaignStaticValue: 'default4',
        pidStaticValue: 'default4',
    });
    linkGenerator.setChannel("af_channel", "default0");
    linkGenerator.setAdset("af_adset", "default0");
    linkGenerator.setAd("af_ad", "default0");
    linkGenerator.setCustomParameter("af_c_id", "af_c_id","default0");
    linkGenerator.setCustomParameter("af_adset_id", "af_adset_id", "default0");
    linkGenerator.setCustomParameter("af_ad_id", "af_ad_id","default0");

    const url = linkGenerator.generateUrl();
    this.setState({ ...this.state, urlAppLink: url })
    install('G-GH7HJZJB30')
  }
  render () {
    return (
      <div className="App">
        <Header handleInstallClick={this.handleInstallClick} />
        <Switch>
          
          <Route path='/' exact>
            <Main handleInstallClick={this.handleInstallClick} />
          </Route>
          
          <Route path='/about'>
            <About />
          </Route>
  
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default App;
