import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import Drawer from '../Drawer/Drawer'
import Button from '../UI/Button/Button'
import './Header.scss'

export default function Header({ handleInstallClick }) {

  const [drawerVisibility, setDrawerState] = useState(false)

  const setDrawerVisibility = () => {
    setDrawerState(!drawerVisibility)
  }

  return (
    <header className="Header">
      <div className="container">
        <div className="header-content">
          <Link to={'/'} className="header-logo" >
            <img src="/images/image_logo.png" alt="logo"></img>
            <img src="/images/dilmil_logo.png" alt="logo-text"></img>
          </Link>
          <div className="header-control">
            <Button 
              text={'download'} 
              cls={['header-btn']}
              onClick={handleInstallClick}
            />
            <div 
              className="mobile-menu"
              onClick={setDrawerVisibility}
              >
              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2 16H30" stroke="#6B4FFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 8H30" stroke="#6B4FFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M2 24H30" stroke="#6B4FFB" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </div>
          </div>
        </div>  
      </div>
      {
        drawerVisibility ? (
          <Drawer 
            setDrawerState={setDrawerVisibility} 
            handleInstallClick={handleInstallClick} 
          />
        ) : ''
      }
    </header>
  )
}
