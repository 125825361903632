import React from 'react'
import Select from '../UI/Select/Select'
import Button from '../UI/Button/Button'
import './Questionarie.scss'

const questions = [
  { text: 'Who are you interested in?', value: 0, selected: true, disabled: true },
  { text: 'I am a Man seeking a Woman', value: 1 },
  { text: 'I am a Woman seeking a Man', value: 2 },
  { text: 'I am a Man seeking a Man', value: 3 },
  { text: 'I am a Woman seeking a Woman', value: 4 }
]

const arr = [
  18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 
  39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 
  50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60 
]
const age = arr.map(el => {
  return { text: el, value: el }
})

export default function Questionarie({ handleInstallClick }) {

  return (
    <div className="Questionarie">
      <h2 className="Questionarie-title">Find Beautiful<br/> Singles in your area</h2>
      <div className="primary-select">
        <Select options={questions} cls={['primary']}/>
      </div>
      <p className="Questionarie-text">Between ages</p>
      <div className="secondary-select">
        <Select options={age} cls={['secondary']} />
        <span> — </span>
        <Select options={age} cls={['secondary']} />
      </div>
      <div className="Questionarie-btn">
        <Button text={'VIEW SINGLES'} cls={['select-btn']} onClick={handleInstallClick} />
      </div>
    </div>
  )
}
